<template>
  <div :id="'select-lemma' + idClass">
    <Select2 v-model="selected" @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'LemmaSelect',
    props: ['exclude', 'lemma', 'isRequired', 'idClass'],
    components: {
      Select2
    },
    data(){
      return {
        lemmaObj: this.lemma,
        selected: null
      }
    },
    mounted() {
      if (this.lemma){
        this.$refs.select2.select2.append(
          `<option selected value="${this.lemma.id}">${this.lemma.display}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a lemma",
          allowClear: !(this.isRequired == true),
          width: '100%',
          dropdownParent: "#select-lemma" + this.idClass,
          minimumInputLength: this.isRequired == true ? 1 : 0,

          ajax: {
            delay: 250,
            url: config.apiUrl + '/lemmas/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term,
                page: params.page || 1,
                exclude: this.exclude ? this.exclude : []
              }
            },
            processResults: (data) => {
              return {
                results: data.results.map(lemma => {
                  if (lemma.articleMainLemmaForm) {
                    return {
                      id: lemma.id,
                      text: `${lemma.display} / ${lemma.articleMainLemmaForm} [${lemma.articleDictionaryAcronym}]`,
                      lemma: lemma
                    }
                  } else {
                    return {
                      id: lemma.id,
                      text: `${lemma.display}`,
                      lemma: lemma
                    }
                  }
                }),
                pagination: {
                  more: data.more
                }
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        let lemma =  this.selected ? event.lemma : null
        this.lemmaObj = lemma
        this.$emit('select', lemma)
      }
    }
  }
</script>
