import { render, staticRenderFns } from "./ProtospellingAutocompleteForModal.vue?vue&type=template&id=f66d4b8c"
import script from "./ProtospellingAutocompleteForModal.vue?vue&type=script&lang=js"
export * from "./ProtospellingAutocompleteForModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports