<template>
  <div class="reference">
    <div v-if="loading || !reference">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="reference.surfaceForm" :pretitle="'Reference details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" v-if="$store.getters.checkRole('legam.dict.reference.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.dict.reference.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button @click="deleteItem" v-if="!$store.getters.checkRole('legam.dict.reference.modify') && $store.getters.checkRole('legam.dict.reference.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Siglum</h6>
                  <!-- <b-form-input
                    id="siglum"
                    v-model="reference.siglum"
                    placeholder="Siglum"
                    v-if="editMode"
                  ></b-form-input> -->
                  <span v-html="reference.siglum"></span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Lemma</h6>
                  <LemmaSelect v-if="editMode" :lemma="reference.lemma" @select="selectLemma" />
                  <span v-else-if="reference.lemma">
                    <router-link :to="{name: 'Lemma', params: {id: reference.lemma.id}}" target="_blank">
                      <span v-html="reference.lemma.display"></span>
                    </router-link>
                  </span>
                </b-col>
                <b-col>
                  <h6>Location</h6>
                  <LocationSelect v-if="editMode" :location="reference.location" @select="selectLocation" />
                  <span v-else>
                    <span v-if="reference.location" v-html="reference.location.display"></span>
                  </span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Public Comment Sense</h6>
                  <b-form-textarea
                    id="public-comment-sense"
                    placeholder="Public Comment Sense"
                    v-model="reference.publicCommentSense"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="reference.publicCommentSense"></span>
                </b-col>
                <b-col>
                  <h6>Public Comment Spelling</h6>
                  <b-form-textarea
                    id="public-comment-spelling"
                    placeholder="Public Comment Spelling"
                    v-model="reference.publicCommentSpelling"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="reference.publicCommentSpelling"></span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Date Spec</h6>
                  <b-form-input
                    id="date-spec"
                    v-model="reference.dateSpec"
                    placeholder="Date Spec"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="reference.dateSpec"></span>
                </b-col>
                <b-col>
                  <h6>Date Num</h6>
                  <b-form-input
                    id="siglum-num-date"
                    v-model="reference.dateNum"
                    placeholder="Date Num"
                    v-if="editMode"
                    type="date"
                  ></b-form-input>
                  <span v-else v-html="reference.dateNum"></span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Surface Form</h6>
                  <b-form-textarea
                    id="surface-form"
                    placeholder="Surface Form"
                    v-model="reference.surfaceForm"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="reference.surfaceForm"></span>
                </b-col>
                <b-col>
                  <h6>Headword</h6>
                  <b-form-textarea
                    id="headword"
                    placeholder="Headword"
                    v-model="reference.headword"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="reference.headword"></span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Context</h6>
                  <b-form-textarea
                    id="context"
                    placeholder="Context"
                    v-model="reference.context"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else v-html="reference.context"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-textarea
                    id="comment"
                    placeholder="Comment"
                    v-model="reference.comment"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="reference.comment"></span>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <h6>Position</h6>
                  <b-form-input
                    id="position"
                    v-model="reference.position"
                    placeholder="Position"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="reference.position"></span>
                </b-col>
                <b-col>
                  <h6>URL</h6>
                  <b-form-input
                    id="url"
                    v-model="reference.url"
                    placeholder="URL"
                    v-if="editMode"
                  ></b-form-input>
                  <a :href="reference.url" target="_blank" v-else>{{reference.url}}</a>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <h6>Suppressed Sense Dictionaries</h6>
                  <DictionarySelect
                    v-if="editMode"
                    :selected="reference.suppressedSenseDictionaries.map(dictionary => dictionary.id)"
                    @select="setSuppressedSenses"
                    :multiple="true"
                  />
                  <span v-else v-html="getSuppressedList(reference.suppressedSenseDictionaries)" class="white-space-pre-line"></span>
                </b-col>
                <b-col>
                  <h6>Suppressed Spelling Dictionaries</h6>
                  <DictionarySelect
                    v-if="editMode" :selected="reference.suppressedSpellingDictionaries.map(dictionary => dictionary.id)"
                    @select="setSuppressedSpellings"
                    :multiple="true"
                  />
                  <span v-else v-html="getSuppressedList(reference.suppressedSpellingDictionaries)" class="white-space-pre-line"></span>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <h6>Suppressed Context Dictionaries</h6>
                  <DictionarySelect
                    v-if="editMode"
                    :selected="reference.suppressedContextDictionaries.map(dictionary => dictionary.id)"
                    @select="setSuppressedContexts"
                    :multiple="true"
                  />
                  <span v-else v-html="getSuppressedList(reference.suppressedContextDictionaries)" class="white-space-pre-line"></span>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
            </article>
          </b-col>
        </b-row>
        <hr class="mt-4">

        <div class="accordion mt-4" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="text-left">
                Studies <small>({{ reference.studies.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                  <StudyAutocomplete @select="addStudy" />
                </b-modal>
                <StudyTable
                  :showTotal="false"
                  :studies="reference.studies"
                  @delete="deleteStudy"
                  :editMode="editMode"
                  v-if="reference.studies.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 class="text-left">
                Scriptas <small>({{ reference.scriptas.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-scripta>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-scripta" title="Add scripta" ok-only ok-variant="secondary" size="lg">
                  <ScriptaAutocomplete @select="addScripta" />
                </b-modal>
                <ScriptaTable
                  :showTotal="false"
                  :scriptas="reference.scriptas"
                  @delete="deleteScripta"
                  :editMode="editMode"
                  v-if="reference.scriptas.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card> -->

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 class="text-left">
                Protospellings <small>({{ reference.protospellings.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-protospelling>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-protospelling" title="Add protospelling" ok-only ok-variant="secondary" size="lg">
                  <ProtospellingAutocomplete @select="addProtospelling" />
                </b-modal>
                <ProtospellingTable
                  :showTotal="false"
                  :protospellings="reference.protospellings"
                  @delete="deleteProtospelling"
                  :editMode="editMode"
                  v-if="reference.protospellings.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-4 class="text-left">
                Senses <small>({{ reference.senses.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-sense>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-sense" title="Add sense" ok-only ok-variant="secondary" size="lg">
                  <SenseAutocomplete @select="addSense" />
                </b-modal>
                <SenseTable
                  :showTotal="false"
                  :senses="reference.senses"
                  @delete="deleteSense"
                  :editMode="editMode"
                  v-if="reference.senses.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'reference'" :objectId="referenceId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  h6 {
    margin-bottom: 2px;
    margin-top: 1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import DictionarySelect from '@/components/DictionarySelect.vue'
  // import ScriptaTable from '@/components/Main/ScriptaTable.vue'
  // import ScriptaAutocomplete from '@/components/Main/ScriptaAutocomplete.vue'
  import ProtospellingAutocomplete from '@/components/ProtospellingAutocompleteForModal.vue'
  import ProtospellingTable from '@/components/ProtospellingTable.vue'
  import SenseAutocomplete from '@/components/SenseAutocompleteForModal.vue'
  import SenseTable from '@/components/SenseTable.vue'
  import LocationSelect from '@/components/Main/LocationSelect.vue'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'
  import config from '@/config.js'
  // import Select2 from 'v-select2-component'

  export default {
    name: 'ReferenceDetails',
    data(){
      return {
        config: config,
        referenceId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      StudyTable,
      StudyAutocomplete,
      // ScriptaTable,
      // ScriptaAutocomplete,
      ProtospellingAutocomplete,
      ProtospellingTable,
      SenseAutocomplete,
      SenseTable,
      LocationSelect,
      LemmaSelect,
      DictionarySelect
    },
    computed: {
      ...mapState({
        reference: state => state.reference.reference,
        // studies: state => state.study.studies
      }),
      // studyOptions(){
      //   return this.studies.map(study => {
      //     return {
      //       id: study.sigleLegam,
      //       text: study.sigleLegam
      //     }
      //   }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      // }
    },
    watch: {
      'reference'() {
        this.loading = false
      }
    },
    mounted(){
      this.$store.dispatch('loadReference', this.$route.params.id)
      // this.$store.dispatch('loadStudies')
    },
    methods: {
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.reference.studies.push(study)
      },
      setSuppressedSenses(dictionaries){
        this.reference.suppressedSenseDictionaries = dictionaries
      },
      setSuppressedSpellings(dictionaries){
        this.reference.suppressedSpellingDictionaries = dictionaries
      },
      setSuppressedContexts(dictionaries){
        this.reference.suppressedContextDictionaries = dictionaries
      },
      getSuppressedList(suppressedDictionaries){
        return suppressedDictionaries.map(dictionary => {
          let langCode = dictionary.languageCode ? ` (${dictionary.languageCode})` : ''
          return `${dictionary.acronym}${langCode}`
        }).join(', ')
      },
      deleteStudy(studyId){
        this.reference.studies = this.reference.studies.filter(study => study.id != studyId)
      },
      selectLocation(location){
        this.reference.locationId = null
        this.reference.location = null
        if (location) {
          this.reference.locationId = location.id
          this.reference.location = location
        }
      },
      selectLemma(lemma){
        this.reference.lemmaId = null
        this.reference.lemma = null
        if (lemma) {
          this.reference.lemmaId = lemma.id
          this.reference.lemma = lemma
        }
      },
      // addScripta(scripta){
      //   this.$bvModal.hide('modal-add-scripta')
      //   this.$store.dispatch('addNotification', {
      //     type: 'success',
      //     text: `Scripta added`
      //   })
      //   this.reference.scriptas.push(scripta)
      // },
      // deleteScripta(code){
      //   this.reference.scriptas = this.reference.scriptas.filter(scripta => scripta.code != code)
      // },
      addProtospelling(protospelling){
        this.$bvModal.hide('modal-add-protospelling')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Protospelling added`
        })
        this.reference.protospellings.push(protospelling)
      },
      deleteProtospelling(protospellingId){
        this.reference.protospellings = this.reference.protospellings.filter(protospelling => protospelling.id != protospellingId)
      },
      addSense(sense){
        this.$bvModal.hide('modal-add-sense')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Sense added`
        })
        this.reference.senses.push(sense)
      },
      deleteSense(senseId){
        this.reference.senses = this.reference.senses.filter(sense => sense.id != senseId)
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadReference', this.referenceId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveReference', this.reference).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteReference', this.reference).then(() => {
              router.push('/references')
            })
          }
        })
      }
    }
  }
</script>
